'use strict';
import tsCobrowsingTitleView from './ts-cobrowsing-title.view.html';
import './ts-cobrowsing-title.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

class tsCobrowsingTitleController {
    constructor($rootScope) {
        'ngInject';

        this.rootScope = $rootScope;
        this.endMeetingModalService = getRootStore().endMeetingConfirmationController;
        this.endMeetingModalService.init(true);
        this.tsCobrowsing = getRootStore().cobrowsingService;
    }

    get requireImage() {
        return this.rootScope.requireImage;
    }
}

export function tsCobrowsingTitleDirective() {
    'ngInject';

    return {
        template: tsCobrowsingTitleView,
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            onEndMeeting: '&'
        },
        controller: tsCobrowsingTitleController,
        controllerAs: 'vm'
    };
}
